<template>
  <div>
    <ul class="cs_inquiry_tab">
      <li class="on">
        <router-link to="" @click.native="openTab('all', $event)"
          >전체</router-link
        >
      </li>
      <li>
        <router-link to="" @click.native="openTab('reType1', $event)"
          >답변완료</router-link
        >
      </li>
      <li>
        <router-link to="" @click.native="openTab('reType0', $event)"
          >미답변</router-link
        >
      </li>
      <li>
        <router-link to="" @click.native="openTab('my', $event)"
          >내 문의</router-link
        >
      </li>
      <li>
        <router-link to="" @click.native="openTab('write', $event)"
          >문의하기<img src="@/assets/images/sub/my_down_ico.png" alt=""
        /></router-link>
      </li>
    </ul>
    <div class="cs_inquity_write" v-if="isWrite">
      <div class="top">
        <textarea
          v-model="content"
          placeholder="문의하실 내용을 적어주세요."
        ></textarea>
      </div>
      <div class="bottom">
        <div class="left">
          <label
            ><input
              type="radio"
              name="open_yn"
              value="0"
              v-model="openYn"
            />공개</label
          >
        </div>
        <div class="left">
          <label
            ><input
              type="radio"
              name="open_yn"
              value="1"
              v-model="openYn"
            />비공개</label
          >
        </div>
        <div class="right">
          <router-link to="" class="black" @click.native="writeQna"
            >등록하기</router-link
          >
        </div>
      </div>
    </div>
    <div class="cs_inquiry">
      <template v-if="listArray.length > 0">
        <div class="box" v-for="list in listArray" :key="list.seq">
          <div class="top">
            <div @click="openContent(list.seq, $event)">
              {{ list.contents | tegRemove }}
            </div>
            <div>
              <span
                v-if="list.mseq === myInfo.member_seq"
                @click="modify(list.seq)"
                >수정</span
              >
              <span
                v-if="list.mseq === myInfo.member_seq"
                @click="deleteQna(list.seq)"
                >삭제</span
              >
            </div>
          </div>
          <ul class="bottom">
            <li>{{ list.mid }}</li>
            <li>{{ day(list.r_date) }}</li>
            <li>
              {{ list.re_mtype === "o" ? "미답볍" : "답변완료" }}
            </li>
            <li>
              <router-link to="" @click.native="openContent(list.seq, $event)"
                ><img src="@/assets/images/sub/my_down_ico.png" alt=""
              /></router-link>
            </li>
          </ul>
          <div
            class="reply_box"
            :id="`org_id_${list.seq}`"
            style="display: none"
          >
            <div class="reply" v-html="list.contents">
              {{ list.contents }}
            </div>
            <div class="re_reply" v-if="list.re_contents">
              <div v-html="list.re_contents">
                {{ list.re_contents }}
              </div>
            </div>
          </div>
          <div
            :id="`modify_id_${list.seq}`"
            class="modify_list"
            style="display: none"
          >
            <div class="cs_inquity_write">
              <div class="top">
                <textarea :value="textAreraBr(list.contents)"></textarea>
              </div>
              <div class="bottom">
                <div class="left">
                  <label
                    ><input
                      type="radio"
                      :name="`open_yn_${list.seq}`"
                      value="0"
                      :checked="parseInt(list.hidden) === 0"
                    />공개</label
                  >
                </div>
                <div class="left">
                  <label
                    ><input
                      type="radio"
                      :name="`open_yn_${list.seq}`"
                      value="1"
                      :checked="parseInt(list.hidden) === 1"
                    />비공개</label
                  >
                </div>
                <div class="right">
                  <router-link
                    to=""
                    class="gray"
                    @click.native="cancelModify(list.seq)"
                    >취소</router-link
                  >
                  <router-link
                    to=""
                    class="black"
                    @click.native="modifyQna(list.seq, $event)"
                    >수정하기</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="box nodata">
          <p>1:1문의가 없습니다.</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: 0,
      category: parseInt(this.$route.params.category) || "카테고리 일반",
      openYn: "0",
      isWrite: false,
      url: this.$route.path,
      content: null,
    };
  },
  mounted() {
    this.$store.dispatch("cs/dataClear");
    this.getList();
  },
  filters: {
    tegRemove(value) {
      return value.replace(/<[^>]*>?/g, "");
    },
  },
  computed: {
    ...mapState("cs", ["result", "msg", "limit", "totalPage", "listArray"]),
    ...mapState("mypage", ["myInfo"]),
  },
  methods: {
    needsLogin() {
      this.$confirm("로그인이 필요합니다.")
        .then(() => {
          this.$store.dispatch("common/setReferer", {
            path: this.url,
          });
          this.$router.push("/member/login");
          return false;
        })
        .catch(() => {
          return false;
        });
    },
    openTab(type, event) {
      const upImg = require("@/assets/images/sub/my_up_ico.png");
      const downImg = require("@/assets/images/sub/my_down_ico.png");

      const menuEl = [...event.target.closest("ul").querySelectorAll("li")];
      menuEl.map((menu) => menu.classList.remove("on"));
      event.target.closest("li").classList.add("on");
      if (type === "write") {
        const hideList = [...document.querySelectorAll(".modify_list")];
        hideList.map((li) => (li.style.display = "none"));
        [...document.querySelectorAll(".bottom")].map(
          (bottom) => (bottom.style.display = "block")
        );
        this.where = type;
        if (this.$store.state.member.isLogin) {
          this.isWrite = !this.isWrite;
          if (this.isWrite) {
            event.target.closest("li").querySelector("img").src = upImg;
          } else {
            event.target.closest("li").querySelector("img").src = downImg;
          }
        } else {
          this.needsLogin();
        }
      } else {
        this.isWrite = false;
        this.where = type;
        if (type == "my") {
          if (this.$store.state.member.isLogin) {
            this.getList(true);
          } else {
            this.needsLogin();
          }
        } else {
          this.getList(true);
        }
      }
    },
    openContent(seq, e) {
      this.cancelModify(seq);
      const textEl = e.target
        .closest(".cs_inquiry > .box")
        .querySelector(".reply_box");
      const imgEl = e.target.closest(".cs_inquiry > .box").querySelector("img");

      const upImg = require("@/assets/images/sub/my_up_ico.png");
      const downImg = require("@/assets/images/sub/my_down_ico.png");
      [...document.querySelectorAll(".box > .reply_box")].map((text) => {
        if (textEl !== text) {
          text.style.display = "none";
        }
      });
      [...document.querySelectorAll(".cs_inquiry > .box")].map((box) => {
        let img = box.querySelector("img");
        if (imgEl !== img) {
          img.src = downImg;
        }
      });
      if (textEl.style.display === "none") {
        imgEl.src = upImg;
        textEl.style.display = "block";
      } else {
        imgEl.src = downImg;
        textEl.style.display = "none";
      }
    },
    day(str) {
      return this.$moment(str).format("YY.MM.DD");
    },
    async getList(reset = false) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("cs/getList", {
        category: this.category,
        page: this.page,
        where: this.where,
        id: "mbqna",
        reset,
      });
      this.page++;
      this.$emit("moreCheck", this.totalPage > this.page);
    },
    async writeQna() {
      if (!this.content || this.content.replace(/\s/g, "").length <= 0) {
        this.content = "";
        this.$toast.default("문의 내용을 작성해 주세요.");
        return false;
      }

      let formData = new FormData();

      formData.append("mode", "board_write"); //기본값
      formData.append("board_id", "mbqna"); //기본값
      formData.append("category", this.category); //기본값
      formData.append("subject", "램 1:1 문의 입니다."); //기본값
      formData.append("goods_seq", 0);
      formData.append("name", this.myInfo.user_name);
      formData.append("oldpw", this.myInfo.password);
      formData.append("contents", this.content);
      formData.append("hidden", this.openYn);

      //   for (let pair of formData.entries()) {
      //     console.log(`${pair[0]}: ${pair[1]}`);
      //   }
      this.content = "";
      await this.$store.dispatch("cs/qnaWrite", formData);
      this.$toast.default(this.msg);
      if (this.result) {
        this.cancelWirte();
        this.getList(true);
      }
    },
    cancelWirte() {
      this.content = null;
      const menuEl = [
        ...document.querySelector(".cs_inquiry_tab").querySelectorAll("li"),
      ];
      menuEl.map((menu) => menu.classList.remove("on"));
      menuEl[0].classList.add("on");
      this.isWrite = false;
      this.where = "all";
    },
    textAreraBr(str) {
      return str.replace(/<br ?\/?>/g, "");
    },
    modify(seq) {
      const downImg = require("@/assets/images/sub/my_down_ico.png");
      [...document.querySelectorAll(".box > .reply_box")].map(
        (text) => (text.style.display = "none")
      );
      [...document.querySelectorAll(".cs_inquiry > .box")].map((box) => {
        box.querySelector("img").src = downImg;
      });
      const hideList = [...document.querySelectorAll(".modify_list")];
      hideList.map((li) => (li.style.display = "none"));
      document.querySelector(`#org_id_${seq}`).style.display = "none";
      document.querySelector(`#modify_id_${seq}`).style.display = "block";
    },
    cancelModify(seq) {
      const orgContent = this.listArray.find(
        (list) => parseInt(list.seq) === parseInt(seq)
      );
      const hideList = [...document.querySelectorAll(".modify_list")];
      hideList.map((li) => (li.style.display = "none"));
      document.querySelector(`#modify_id_${seq}`).style.display = "none";
      document.querySelector(`#modify_id_${seq}  textarea`).value =
        this.textAreraBr(orgContent.contents);
    },
    modifyQna(seq) {
      const content = document
        .querySelector(`#modify_id_${seq}`)
        .querySelector("textarea").value;
      const hidden = document.querySelector(
        `input[name='open_yn_${seq}']:checked`
      ).value;

      //console.log(content, hidden);

      if (!content || content.replace(/\s/g, "").length <= 0) {
        document
          .querySelector(`#modify_id_${seq}`)
          .querySelector("textarea").value = "";
        this.$toast.default("문의 내용을 작성해 주세요.");
        return false;
      }
      let formData = new FormData();

      formData.append("mode", "board_modify"); //기본값
      formData.append("board_id", "mbqna"); //기본값
      formData.append("category", this.category);
      formData.append("subject", "램 1:1 문의 입니다."); //기본값
      formData.append("goods_seq", 0);
      formData.append("seq", seq);
      formData.append("name", this.myInfo.user_name);
      formData.append("oldpw", this.myInfo.password);
      formData.append("contents", content);
      formData.append("hidden", hidden);

      // for (let pair of formData.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }

      this.$confirm("문의글을 수정 하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("cs/modifyQna", {
            formData,
            seq,
            content,
          });
          this.$toast.default(this.msg);
          this.cancelModify(seq);
        })
        .catch(() => {
          return false;
        });
    },
    deleteQna(seq) {
      if (!seq) return faslse;
      let formData = new FormData();

      formData.append("mode", "board_delete"); //기본값
      formData.append("board_id", "mbqna"); //기본값
      formData.append("delseq", seq);

      this.$confirm("문의글을 삭제 하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("cs/deleteQna", {
            formData,
            seq,
          });
          this.$toast.default(this.msg);
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  &.nodata {
    text-align: center;
    padding: 15px 0;
  }
}
.modify_list {
  margin-top: 10px;
}
</style>
